// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  // background: url('~assets/utils/images/logo-inverse.png');
}

.app-logo-inverse {
  height: 50px;
  width: 100%;
  background: url('~assets/utils/images/vyzeo-logo.png') no-repeat center;
  background-size: contain;
}

.vyzeo-logo-inverse {
  height: 170px;
  width: 100%;
  background: url('~assets/utils/images/playstore-icon.png') no-repeat center;
  background-size: contain;
  background-color: white;
  border-radius: 20%;
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: .6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}