// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";
// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/navs";
@import "elements/badges";
@import "elements/cards";
@import "elements/tabs";
@import "elements/accordions";
@import "elements/modals";
@import "elements/loaders";
@import "elements/progressbar";
@import "elements/timeline";
@import "elements/listgroup";
@import "elements/forms";
@import "elements/pagination";
@import "elements/chat";
@import "elements/forms-wizard";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";
@import "widgets/profile-boxes/profile-boxes";

// PAGES

@import "pages/userpages";

// Applications

@import "applications/applications-base";

// COMPONENTS

// Perfect Scrollbar
@import "components/perfect-scrollbar/perfect-scrollbar";

// RC Tabs
@import "components/rc-tabs/rc-tabs";

// Loading Buttons
@import "components/loading-buttons/loading-buttons";

// Hamburger Button
@import "components/hamburgers/hamburgers";

// Drawer
@import "components/drawer/drawer";

// Notifications
@import "components/notifications/sweetalerts";
@import "components/notifications/toastify";

// Modals
@import "components/modals/rodal";

// Progress Bar
@import "components/progress-bar/progress-bar";

// Slick Carousel
@import "components/slick-carousel/slick/slick";

// Block loading
@import "components/block-loading/block-loading";

// Popovers & Tooltips
@import "components/popovers-tooltips/popovers-tooltips";

// Sticky Elements
@import "components/sticky-elements/sticky-elements";

// Calendar
@import "components/calendar/calendar";

// Splitters
@import "components/splitters/splitters";

// Tree View
@import "components/tree-view/tree-view";

// Tables
@import "components/tables/tables";

// Icons
@import "components/icons/linearicons";
@import "components/icons/p7icons";

// FORMS

@import "components/forms/datepicker/datepicker";
@import "components/forms/rangeslider/rangeslider";
@import "components/forms/multiselect/multiselect";
@import "components/forms/wysiwyg/wysiwyg";
@import "components/forms/toggle-switch/switch";
@import "components/forms/dropzone/dropzone";
@import "components/forms/typeahead/typeahead";

// REACT WIDGETS

@import "components/react-widgets/scss/react-widgets";

// DEMO

@import "demo-ui/demo";

// Responsive

@import "layout/responsive/responsive-base";

// .customSwitch{
//     input[type=checkbox]{
//         height: 0;
//         width: 0;
//         visibility: hidden;
//     }
//     label {
//         cursor: pointer;
//         text-indent: 1px;
//         width: 69px;
//         height: 31px;
//         background: #fc1919;
//         display: block;
//         border-radius: 100px;
//         position: relative;
//         &::after {
//             content: '';
//             position: absolute;
//             top: 4px;
//             left: 6px;
//             width: 20px;
//             height: 20px;
//             background: #fff;
//             border-radius: 90px;
//             transition: 0.3s;
//         }
//     }
//     input:checked + label {
//         background:  #4bd1dc;
//     }
//     input:checked + label:after {
//         left: calc(100% - 5px);
//         transform: translateX(-100%);
//     }

// }


.toggle-button-cover {
  display: table-cell;
  position: relative;
  top: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  .button-cover {
    height: 100px;
    margin: 20px;
    background-color: #fff;
    -webkit-box-shadow: 0 10px 20px -8px #c5d6d6;
    box-shadow: 0 10px 20px -8px #c5d6d6;
    border-radius: 4px;

    &:before {
      counter-increment: button-counter;
      content: counter(button-counter);
      position: absolute;
      right: 0;
      bottom: 0;
      color: #d7e3e3;
      font-size: 12px;
      line-height: 1;
      padding: 5px;
    }
  }

  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    top: 50%;
    width: 49px;
    height: 24px;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    background-color: #02d5de;
    -webkit-transition: 0.3s ease all;
    transition: 0.3s ease all;
    z-index: 1;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  #button-3 .knobs:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 4px;
    width: 19px;
    height: 18px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    padding: 0px 4px;
    background-color: #03a9f4;
    border-radius: 50%;
    -webkit-transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  }

  #button-3 .checkbox:active+.knobs:before {
    width: 46px;
    border-radius: 100px;
  }

  #button-3 .checkbox:checked:active+.knobs:before {
    margin-left: -26px;
  }

  #button-3 .checkbox:checked+.knobs:before {
    content: "";
    left: 25px;
    background-color: #f44336;
  }

  #button-3 .checkbox:checked~.layer {
    background-color: #fcebeb;
  }
}


@media (min-width: 1700px) {
  .row-xxl {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding-right: 15px;
    padding-left: 15px;
  }
}